import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
// import { useParams } from "react-router-dom";
// import Pagination from "react-pagination-js";
// import { toast } from "react-toastify";
// import { Tooltip } from "antd";
// import loader from "../../methods/loader";
// import methodModel from "../../methods/methods";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
// import { CiAlignRight } from "react-icons/ci";
// import { MdDataObject } from "react-icons/md";
// import ModalOKR from "./ModalOKR";
import { useDispatch, useSelector } from "react-redux";
// import AlignmentOKR from "./AlignmentOKR";
// import { FaUsers } from "react-icons/fa6";
// import { FaUsersSlash } from "react-icons/fa6";
// import { LuCircleDotDashed } from "react-icons/lu";
// import { BsFileEarmarkBarGraph } from "react-icons/bs";
// import { MdOutlineSignalCellularAlt } from "react-icons/md";
// import { sessions } from "../../actions/session";
import Select from "react-select";
import { Menu } from '@headlessui/react'
import Layout from '../../components/global/layout'
import OKRs from "../OKRs/OKRs"
import moment from "moment";
import datepipeModel from "../../models/datepipemodel";
// import { Router } from "react-router-dom/cjs/react-router-dom.min";
// import Switch from "antd";

const Dashboardss = () => {
  const [LoadingState, setLoadingState] = useState(false);
  const user = useSelector((state) => state.user)
  const [data, setdata] = useState([]);
  const [filter, setfilter] = useState({ search: "", ownerId: "" });
  const [total, settotal] = useState(0);
  const [detail, setdetail] = useState();
  const [loading, setloading] = useState(false);
  const navigate = useHistory();
  const [upto, setupto] = useState()
  const [end, setend] = useState()
  const [Profiledata, setProfiledata] = useState();
  // Create OKRs
  const sessionid = localStorage.getItem("sessionID")
  const [sessionId, setSessionId] = useState(sessionid);

  const [owners, setowners] = useState([]);

  const [totalObjective, settotalobjective] = useState()

  const [totalKeyResult, setTotalKeyResult] = useState()
  const [keyResultsOnTrack, setkeyResultsOnTrack] = useState()
  const [keyResultsAtRisk, setkeyResultsAtRisk] = useState()
  const [keyResultsOffTrack, setkeyResultsOffTrack] = useState()
  const ownerID = JSON.parse(localStorage.getItem("ownerId"))
  const [sessionme, setsession] = useState();
  const sessionName = localStorage.getItem("sessionNAme")

  const [enddate,setenddate] = useState()

  useEffect(() => {
    if (sessionid) {
      setSessionId()
      getcounts(sessionid)
    } else {
      sessions()
    }
    getTotalUser()
  }, [])

  const clearOKRForm = (sessionId) => {
    navigate.push(`/sessions/okrs/${sessionId}`)
  }

  useEffect(() => {
    sessions()
  
  }, [])
  

  const PlanDayReturner = () => {
    let getDayInMillis = moment(enddate || localStorage.getItem('endate')).diff(moment());
    let getDay = moment.duration(getDayInMillis).asDays();
    getDay = Math.round(getDay);
    return getDay?getDay + 1:'--'
    }
    


  const getTotalUser = (p = {}) => {
    ApiClient.get(`profile?id=${user?.id}`).then((res) => {
      if (res.success) {
        setProfiledata(res?.data);

        const planValidUpToDate = new Date(res?.data?.planValidUpTo);
        const createdAtDate = new Date(res?.data?.createdAt);

        // Set end date to the beginning of the next day in UTC timezone
        const nextDay = new Date(createdAtDate);
        nextDay.setDate(nextDay.getUTCDate() + 1);
        nextDay.setUTCHours(0, 0, 0, 0);

        setupto(planValidUpToDate);
        setend(nextDay);
      }
    });
  };

// https://stageapi.kanam.io/sessions?search=&ownerId=&sortBy=start_date asc&addedBy=661e1fcfe3f6f78a0b3c05ef&active=true

  const sessions = () => {
    let fil;

    if (user?.role == "employee") {
      fil = {
        ...filter, 
        // sortBy: localStorage.getItem('CreatedAt') == 'true' ? "start_date asc" : "updatedAt desc",
        addedBy: user?.addedBy,
        sortBy: "start_date asc",
        active: true
        // active:localStorage.getItem('CreatedAt') == 'true' ? true :''
      }
    } else {
      fil = {
        ...filter,
        sortBy: "start_date asc",
        active: true,
        // sortBy: localStorage.getItem('CreatedAt') == 'true' ? "start_date asc" : "updatedAt desc",
        addedBy: user?.id || user?._id,
        // active:localStorage.getItem('CreatedAt') == 'true' ? true :''
      }
    }

    ApiClient.get(`sessions`, fil).then(res => {
      if (res?.success && res.data.length > 0) {
        const sessionId = res.data[0]._id || res.data[0].id;
        const sessionName = res.data[0]?.title 
        setsession(res?.data[0]?.title)
        console.log(res?.data[0]?.title,"jdshfksdhj")
        setSessionId(sessionId);
        if (!localStorage.getItem('endate')) {
          setenddate(res.data[0]?.end_date)
        }
        getcounts(sessionId);
        if (!localStorage.getItem('sessionNAme')) {
        // localStorage.setItem("sessionNAme",sessionName)
        }
        // getData(sessionId)
        // setsession(res?.data[0]?.title)
        // setdata(res?.data[0])

        // history.push(`/OKRs/${sessionId}`);

      } else {
        // getcounts();
      }
    });
  };

  const getcounts = (sessionId = "") => {
    let str = ownerID?.map((itm) => itm.value)?.toString()||'';
    let payload = {}
    if (user?.role == "employee") {
      payload = { ownerIds: user?.id || user?._id ,session: sessionid || sessionId}
      // payload = { ownerIds: str }
      // payload={ownerIds:'6613c83109ddd7fbf4ab68d7'}
    } else {
      payload = { session: sessionid || sessionId,ownerIds: str  }

    }
    ApiClient.get(`get/count`, { ...payload }).then(res => {
      if (res?.success) {
        settotalobjective(res?.totalObjective);
        setTotalKeyResult(res?.totalKeyResult);
        setkeyResultsOnTrack(res?.keyResultsOnTrack);
        setkeyResultsAtRisk(res?.keyResultsAtRisk);
        setkeyResultsOffTrack(res?.keyResultsOffTrack);
      }
    });
  }






  return (
    <Layout>
      <div className="cards_sales">
        <div className="highlighted-name mb-2">
          {user?.role ? <h4 className="d-flex align-items-center hand"><img src="/assets/img/hand1.gif " /> Hi <span className="text-capitalize">  {user?.fullName.split(" ")[0] || user?.firstName.split(" ")[0]}</span></h4> : <></>}
        </div>
        <div className="mt-4 analytics-box">
          <p><span>Analytics of current session :</span><span> <b>{sessionme || ""}</b></span></p>
        </div>

        <p className="shadowit mt-3">Overview</p>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6  col-xl-4 mt-3">
            <div className="sales_section">
              <div className="main_sales">
                <div className="sales_headings">
                  <div>
                    <p className="sub_heading">Total number of objectives</p>
                  </div>
                  <div>
                    <img src="/assets/img/bag.png" className="wallets_img" />
                  </div>
                </div>
                <div className="sales_icons">
                  <h3 className="Amount_sales">{totalObjective || 0}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
            <div className="sales_section">
              <div className="main_sales">
                <div className="sales_headings">
                  <div>
                    <p className="sub_heading"> Remaining Days </p>
                  </div>
                  <div>
                    <img src="/assets/img/bag.png" className="wallets_img" />
                  </div>
                </div>
                <div className="sales_icons">

{/* {user.planData?.isTrial&&!user?.planAmountPaid?<>
  <h3 className="Amount_sales">{datepipeModel.getDays(new Date(),user?.trial_end)}</h3>
</>:<>

</>} */}

<h3 className="Amount_sales">{PlanDayReturner()}</h3>



                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6  col-xl-4 mt-3">
            <div className="sales_section">
              <div className="main_sales">
                <div className="sales_headings">
                  <div>
                    <p className="sub_heading">Total number of Key Results</p>
                  </div>
                  <div>
                    <img src="/assets/img/bag.png" className="wallets_img" />
                  </div>
                </div>
                <div className="sales_icons">
                  <h3 className="Amount_sales">{totalKeyResult || 0}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
            <div className="sales_section">
              <div className="main_sales">
                <div className="sales_headings">
                  <div>
                    <p className="sub_heading">Key Results On Track</p>
                  </div>
                  <div>
                    <img src="/assets/img/bag.png" className="wallets_img" />
                  </div>
                </div>
                <div className="sales_icons">
                  <h3 className="Amount_sales">{keyResultsOnTrack || 0}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
            <div className="sales_section">
              <div className="main_sales">
                <div className="sales_headings">
                  <div>
                    <p className="sub_heading">Key Results At Risk</p>
                  </div>
                  <div>
                    <img src="/assets/img/bag.png" className="wallets_img" />
                  </div>
                </div>
                <div className="sales_icons">
                  <h3 className="Amount_sales">{keyResultsAtRisk || 0}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
            <div className="sales_section">
              <div className="main_sales">
                <div className="sales_headings">
                  <div>
                    <p className="sub_heading">Key Results Off Track</p>
                  </div>
                  <div>
                    <img src="/assets/img/bag.png" className="wallets_img" />
                  </div>
                </div>
                <div className="sales_icons">
                  <h3 className="Amount_sales">{keyResultsOffTrack || 0}</h3>
                </div>
              </div>
            </div>
          </div>

        </div>

        <p className="shadowit mt-3">My Current Focus</p>

        {totalObjective == 0 ? 
        <>
       {user?.role != 'employee' ? <div className="success-story">
              <div className="text-center">
                <p className="text-center">
                  <h3>Start Your Success Story Now</h3>
                </p>
              </div>
              <div className="create_okrs">
              <button
                        className="btn btn-primary"
                        onClick={(e) => clearOKRForm(sessionId)}
                        id="OpenCreateOKRModal"
                        data-toggle="modal"
                        data-target="#OKRsModal"
                      >
                        Create OKRs
                      </button>
              </div>
            </div> : <></>}</>:   <>
            {sessionId?<>
              <OKRs layout={false} ownerID={ownerID} sessionId={sessionId} getcounts={getcounts} />
            </>:<></>}
            </>       
           }

      </div>

    </Layout>

  )
}

export default Dashboardss
